import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useServerContext } from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'
import {
  Region,
  transformRegionToMap,
  useMultiSite,
  useNavigation,
  useEinstein,
  useCookie
} from 'msp-integrations'
import { MainWrapper, JsonLd } from 'msp-components'
import Seo from '@/components/seo'
import PageLayout from '@/components/cms/page-layout'
import { MAX_PAGE_CACHE_AGE } from '@/constants'

const Page = (props: any) => {
  const { children, page, pageNotFound } = props
  const { isMarketingAllowed } = useCookie()
  const { buildUrl } = useMultiSite()
  const navigate = useNavigation()
  const einstein = useEinstein()
  const { pathname } = useLocation()
  if (pageNotFound) {
    navigate(buildUrl('/page-not-found'))
  }
  useEffect(() => {
    einstein.sendViewPage(pathname)
  }, [isMarketingAllowed])

  const regions = transformRegionToMap(page?.content.regions)

  return (
    <>
      <Seo
        title={page?.meta?.title}
        description={page?.meta?.description}
        keywords={page?.meta?.keywords}
        canonicals={page?.meta?.canonicals}
      />
      {page?.id === 'about' && <JsonLd type='Organization' />}
      {page?.id === 'home' && <JsonLd type='SearchAction' />}
      {page?.id === 'faq' && (
        <JsonLd
          type='FAQPage'
          object={page?.content?.regions
            ?.find((r) => r.id === 'main')
            ?.components?.find((c) => c.type_id === 'pwa.accordion')
            ?.regions[0]?.components.map((a) => ({
              question: a.data.headline,
              answer: a.regions[0].components[0].data.richText.value
            }))}
        />
      )}
      <MainWrapper fullWidth={page.id === 'home'}>
        <Region region={regions.get('main')} noWrapper />
        <div>{children}</div>
        <Region region={regions.get('bottomRegion')} noWrapper />
      </MainWrapper>
    </>
  )
}

const PageWrapper = (props: any) => {
  const { res } = useServerContext()

  if (res) {
    res.set('Cache-Control', `max-age=${MAX_PAGE_CACHE_AGE}`)
  }

  return <PageLayout {...{ ...props, ChildPage: Page }} />
}

export default PageWrapper
